@font-face {
    font-family: 'Montserrat-Regular';
    src: url('../font/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-Medium';
    src: url('../font/Montserrat-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-SemiBold';
    src: url('../font/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-Bold';
    src: url('../font/Montserrat-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

body {
    padding: 0;
    margin: 0;
    font-family: 'Montserrat-Medium';
    background-color: #f8f8f8;
}

.is-load {
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100%;
    z-index: 2;
    position: fixed;
    background: rgba(57, 183, 32, 0.9);
}

.is-load img {
    z-index: 10;
    height: 100vh;
    width: 100%;
}

.card {
    z-index: 0;
}

.header-menu {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    background-color: white;
    box-shadow: 1px 1px 10px solid grey;
    height: 70px;
    position: fixed;
    top: 0;
    z-index: 1;
    width: 100%;
    border-bottom: 1px solid rgb(207, 207, 207);
}

.header-menu .menus img {
    height: 20px;
    width: 25px;
}

.header-menu .logo img {
    height: 45px;
}

.header-menu .alo img {
    width: 100px;
}

.drawermenu {
    display: flex;
    left: 0;
    top: 0;
    position: fixed;
    background-color: white;
    height: 100%;
    z-index: 6;
}

.drawermenu .close {
    margin-right: 15px;
    font-size: 30px;
}

.drawermenu .logo {
    align-items: center;
    text-align: center;
}

.drawermenu .logo img {
    margin-top: 15px;
    width: 180px;
    height: 50px;
}

.accordion-item {
    border: 0 !important;
    margin-bottom: -12px !important;
}

.accordion-button:not(.collapsed) {
    background-color: white !important
}

.accordion-button:focus {
    border: 0 !important;
    height: 10px !important;
}

.drawermenu .menuler {
    margin-top: 20px;
    font-size: 14px;
}

.drawermenu .menuler .accordion-body {
    padding-top: -20px;
}

.drawermenu .menuler .accordion-button {
    font-size: 14px;
    height: 45px;
}

.drawermenu .menuler .altsiz {
    text-align: left;
    margin-left: 20px;
    margin-top: 12px;
}

.drawermenu .menuler .altbaslik {
    text-align: left;
}

.drawermenu .menuler .altbaslik hr {
    margin-left: 0;
    margin-right: 0;
    margin-top: 3px;
    margin-bottom: 3px;
    color: grey;
}

.drawermenu .social {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.drawermenu .social div {
    margin-left: 20px;
}

.drawermenu .social img {
    height: 25px;
}

.home-page .main-slider {
    z-index: -1;
}

.home-page .anaSlayt {
    margin-top: 70px;
    width: 100%;
    z-index: -1;
    position: relative;
}

.anaSlayt>img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.anaSlayt>div {
    position: absolute;
    color: #fff;
    width: 100%;
    height: 200px;
    background-color: rgba(0, 0, 0, 0.2);
    text-align: center;
    top: 0;
    padding-top: 40px;
    font-family: 'Montserrat-Bold';
    font-size: 2.5em;
}

.home-page .eregli-baskan {
    padding-top: 20px;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    background-color: #f8f8f8;
}

.home-page .eregli-baskan .baskan .baskanimg {
    height: 130px;
    border-radius: 20px;
}

.home-page .eregli-baskan .metin {
    margin-left: 15px;
    align-items: flex-start;
    text-align: left;
}

.home-page .eregli-baskan .metin span {
    font-size: 13px;
}

.home-page .eregli-baskan .metin small {
    font-size: 11px;
    color: rgb(64, 64, 64);
}

.home-page .button-location {
    width: 100%;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
}

.home-page .button-location .kesfet-button {
    margin-top: -25px;
    width: 40%;
    height: 45px;
    background-color: #14441e;
    color: white;
    font-size: 15px;
    align-items: center;
    text-align: center;
    padding-top: 12px;
    border-radius: 20px;
}

.home-page .ana-baslik {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding-left: 25px;
    padding-right: 25px;
    color: rgb(64, 64, 64);
}

.home-page .ana-baslik .active {
    border-bottom: 2px solid #dcdc0f;
    color: #dcdc0f;
    padding-bottom: 7px;
}

.home-page .ana-baslik .pasif {
    color: rgb(64, 64, 64);
    margin-bottom: 20px;
}

.home-page .header-content .haberler {
    background-color: #f8f8f8;
    padding-top: 20px;
    padding-bottom: 20px;
}

.home-page .header-content .haberler img {
    width: 120px;
    height: 120px;
    border-radius: 10px 10px 10px 10px;
}

.home-page .header-content .haberler .content {
    display: flex;
    justify-content: flex-start;
    background-color: white;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 15px;
    margin-top: 0px;
    height: 140px;
    align-items: center;
    border-radius: 20px;
}

.home-page .header-content .haberler .content:hover {
    box-shadow: 2px 2px 10px solid black;
}

.home-page .header-content .haberler .content .tarih {
    color: grey;
    font-size: 14px;
}

.home-page .header-content .haberler .content .baslik {
    font-weight: bold;
    font-size: 14px;
    color: black;
    margin-top: 5px;
}

.home-page .header-content .haberler .content .aciklama {
    margin-top: 5px;
    font-size: 12px;
    color: rgb(64, 64, 64);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.home-page .header-content .eczane .tumhaberler {
    text-align: center;
    align-items: center;
}

.home-page .header-content .eczane .eczane-button {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
}

.home-page .header-content .eczane .eczane-button a {
    text-decoration: none;
    color: black;
    font-size: 14px;
}

.home-page .header-content .eczane .eczane-button .telefon {
    margin-right: 10px;
    font-size: 12px;
    align-items: center;
    text-align: center;
    border-radius: 20px;
    background-color: #dcdc0f;
    border: 0;
    color: black;
    padding-left: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 30px
}

.home-page .header-content .eczane .eczane-button .yol-tarifi {
    font-size: 12px;
    align-items: center;
    text-align: center;
    border-radius: 20px;
    background-color: #dcdc0f;
    border: 0;
    color: black;
    padding-left: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 30px
}

.home-page .header-content .haberler .tumhaberler a {
    color: rgb(64, 64, 64);
    font-weight: 900;
}

.bottom-bar {
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 1px solid rgb(207, 207, 207);
    display: flex;
    justify-content: space-around;
    height: 55px;
    background-color: white;
    padding-left: 0px;
    padding-right: 15px;
    align-items: center;
    text-align: center;
    z-index: 1;
}

.bottom-bar .item {
    align-items: center;
    text-align: center;
}

.bottom-bar img {
    height: 17px;
}

.bottom-bar span {
    font-size: 11px;
    color: grey;
}

.bottom-bar .item .text {
    margin-top: -7px;
}

.home-page .header-content .eczane {
    background-color: #f8f8f8;
    padding-top: 20px;
    padding-bottom: 20px;
}

.home-page .header-content .eczane .content {
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 20px;
    background-color: white;
    border-radius: 20px;
}

.home-page .header-content .eczane .content .ust {
    display: flex;
    justify-content: space-between;
}

.home-page .header-content .eczane .content .ust span {
    color: grey;
    font-size: 13px;
}

.home-page .header-content .eczane .content .orta {
    margin-top: 8px;
}

.home-page .header-content .eczane .content .orta span {
    font-size: 14px;
    color: black;
    font-weight: bold;
}

.home-page .header-content .eczane .content .alt {
    margin-top: 10px;
    color: rgb(64, 64, 64);
    font-size: 13px;
}

.home-page .header-content .eczane .tumeczaneler {
    text-align: center;
}

.home-page .header-content .eczane .tumeczaneler a {
    color: rgb(64, 64, 64);
    font-weight: 900;
}

.home-page .en-alt {
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
}

.home-page .en-alt img {
    height: 30px;
}

.home-page .en-alt .whatsapp {
    display: flex;
    justify-content: flex-start;
    width: 50%;
    background-color: #ececec;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    align-items: center;
    text-align: center;
}

.home-page .en-alt .kamera {
    display: flex;
    justify-content: flex-start;
    width: 50%;
    background-color: #dcdc0f;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    align-items: center;
    text-align: center;
}

.home-page .en-alt .kamera .buyuk {
    font-size: 25px;
    font-weight: 900;
}

.home-page .en-alt .kamera .kucuk {
    font-size: 12px;
}

.home-page .header-content .vefatlar {
    background-color: #f8f8f8;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.home-page .header-content .vefatlar .content {
    background-color: white;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 20px;
    padding-left: 20px;
    margin-bottom: 20px;
    border-radius: 20px;
}

.home-page .header-content .vefatlar .content a {
    text-decoration: none;
    color: #dcdc0f;
}

.home-page .header-content .vefatlar .content .baslik {
    font-size: 14px;
    color: black;
    font-weight: 900;
}

.home-page .header-content .vefatlar .content .aciklama {
    color: grey;
    font-size: 12px;
    margin-top: 10px;
}

.home-page .header-content .vefatlar .tumvefatlar {
    text-align: center;
}

.home-page .header-content .vefatlar .tumvefatlar a {
    color: black;
    font-weight: 900;
}

/* Belediyemiz */

.icerikdetay,
.pages,
.belediyemiz {
    background-color: #f8f8f8;
    padding-top: 10px;
    padding-bottom: 70px;
}

.belediyemiz .content p {
    font-size: 13px;
}

.icerikdetay .content {
    font-size: 14px;
}

.icerikdetay .content div {
    overflow: auto;
    /* white-space: nowrap; */
}

.pages .video-galeri .item {
    position: relative;
}

.pages .video-galeri {
    text-align: center;
}

.pages .video-galeri .item span {
    position: absolute;
    width: 80%;
    margin-left: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 15px;
}

.pages .video-galeri .content span {
    font-size: 12px;
    text-align: center;
}

.pages .video-galeri .item img {
    border-radius: 20px;
    width: 80%;
}

.head {
    position: fixed;
    top: 0;
    height: 55px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #dedede;
    /* z-index: 4; */
}

.head .sol .head {
    position: inherit;
    z-index: 5;
}

.head .sol .head img {
    position: inherit;
    height: 55px;
    width: 130px;
    z-index: 0;
}

.head .sol .geri {
    position: fixed;
    top: 1;
    left: 20;
    z-index: 6;
}

.head .sol .geri img {
    position: fixed;
    margin-top: -38px;
    margin-left: 20px;
    height: 22px;
    z-index: 6;
}

.head .menuust img {
    height: 20px;
    margin-top: 18px;
    margin-right: 20px;
}

.head .detay {
    margin-top: 10px;
    margin-left: -140px;
}

.head .detay span {
    font-size: 20px;
    color: #0c601e;
}

.icerikdetay .baslik,
.pages .baslik,
.belediyemiz .baslik {
    width: 86%;
    margin-left: 7%;
    margin-top: 80px;
    margin-bottom: 20px;
    box-shadow: 2px 2px 7px grey;
    border-radius: 15px;
    padding-top: 7px;
    padding-bottom: 2px;
    padding-left: 15px;
    color: #0c601e;
    font-weight: 900;
}

.icerikdetay .kapakresim img,
.pages .kapakresim img,
.belediyemiz .kapakresim img {
    width: 86%;
    margin-left: 7%;
    border-radius: 20px;
    margin-bottom: 20px;
}

.icerikdetay .content,
.pages .content,
.belediyemiz .content {
    width: 86%;
    margin-left: 7%;
    background-color: white;
    border-radius: 15px;
    padding: 10px;
    color: rgb(64, 64, 64);
}

.icerikdetay .fotogaleri {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    padding-bottom: 100px;
}

.icerikdetay #myImg {
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
}

.icerikdetay #myImg:hover {
    opacity: 0.7;
}

.icerikdetay .modal {
    display: none;
    position: fixed;
    z-index: 10;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.9);
}

.icerikdetay .modal-content {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
}

.icerikdetay .modal-content {
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.6s;
    animation-name: zoom;
    animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
    from {
        -webkit-transform: scale(0)
    }

    to {
        -webkit-transform: scale(1)
    }
}

@keyframes zoom {
    from {
        transform: scale(0)
    }

    to {
        transform: scale(1)
    }
}

.icerikdetay .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.icerikdetay .close:hover,
.icerikdetay .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}


@media only screen and (max-width: 700px) {
    .icerikdetay .modal-content {
        width: 100%;
    }
}

.pages .galeri,
.belediyemiz .galeri {
    column-count: 2;
    margin-bottom: 50px;
    padding-left: 7%;
    margin-top: 25px;
    padding-bottom: 20px;
}

.pages .galeri .item,
.belediyemiz .galeri .item {
    margin-bottom: 20px;
    display: inline-block;
}

.basliks {
    font-size: 10px;
}

.pages .galeri .item img,
.belediyemiz .galeri .item img {
    width: 80%;
    border-radius: 15px;
}

.pages .foto-galeri {
    display: inline-block;
    align-items: center;
    text-align: center;
}

.pages .foto-galeri .item-foto {
    display: inline-block;
}

.pages .item-foto img {
    height: 170px;
    float: left;
    border-radius: 20px;
    margin-bottom: 10px;
    margin-top: 20px;
    padding: 10px;
}

/* Belediyemiz Bitiş */

/* İlçemiz */

.ilcemiz {
    background-color: #f8f8f8;
}

.ilcemiz .baslik {
    width: 86%;
    margin-left: 7%;
    margin-top: 80px;
    margin-bottom: 20px;
    box-shadow: 2px 2px 7px grey;
    border-radius: 15px;
    padding-top: 7px;
    padding-bottom: 2px;
    padding-left: 15px;
    color: #0c601e;
    font-weight: 900;
}

.ilcemiz .kapakresim img {
    width: 86%;
    margin-left: 7%;
    border-radius: 20px;
    margin-bottom: 20px;
}

.ilcemiz .content {
    width: 86%;
    margin-left: 7%;
    background-color: white;
    border-radius: 15px;
    padding: 10px;
    color: rgb(64, 64, 64);
    margin-bottom: 55px;
}

.ilcemiz .content strong {
    font-size: 16px;
}

.ilcemiz .content p {
    font-size: 13px;
}

/* İlçemiz Bitiş */

/* Bize Yazın */

.bize-yazin {
    background-color: #f8f8f8;
    height: 100vh;
}

.bize-yazin .baslik {
    width: 86%;
    margin-left: 7%;
    margin-top: 80px;
    margin-bottom: 20px;
    box-shadow: 2px 2px 7px grey;
    border-radius: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    margin-bottom: 20px;
    color: #0c601e;
    display: flex;
    justify-content: space-between;
}

.bize-yazin .baslik .img {
    margin-right: 20px;
    margin-top: 10px;
    opacity: 0.5;
}

.bize-yazin .iletisim {
    margin-bottom: 60px;
    padding: 20px;
}

.bize-yazin .iletisim .card {
    border-radius: 20px;
}

.bize-yazin .ilcemiz .butonbuton {
    width: 100%;
}

.bize-yazin .iletisim .buton {
    width: 50%;
    margin-left: 25%;
    margin-top: 10px;
    background-color: #dcdc0f;
    border: none;
    border-radius: 20px;
    color: #0c601e;
    padding-top: -5;
}

.bize-yazin .iletisim .input {
    margin-bottom: 5px;
}

/* Bize Yazın Bitiş */

/* Menu */

.menu {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    z-index: 5;
}

.menu:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    opacity: 0;
    will-change: opacity;
    pointer-events: none;
    transition: opacity 0.3s cubic-bezier(0, 0, 0.3, 1);
}

.menu--visible {
    pointer-events: auto;
}

.app-menu {
    position: relative;
    width: 250px;
    height: 100%;
    background-color: #ffffff;
    color: #465048;
    font-family: 'Montserrat-Bold';
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    -webkit-transform: translateX(-103%);
    transform: translateX(-103%);
    display: flex;
    flex-direction: column;
    will-change: transform;
    z-index: 5;
    pointer-events: auto;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.menu--visible .app-menu {
    -webkit-transform: none;
    transform: none;
}

.menu--animatable .app-menu {
    transition: all 130ms ease-in;
}

.menu--visible.menu--animatable .app-menu {
    transition: all 330ms ease-out;
}

.menu--visible.menu:after {
    opacity: 1;
    pointer-events: auto;
}

.menu .closeNav {
    position: absolute;
    top: 15px;
    left: 215px;
    font-size: 20px;
}

.menu .ust {
    padding: 10px;
    text-align: center;
}

.menu .ust img {
    width: 140px;
    margin-top: 40px;
}

.menu .menuler {
    border-bottom: 1px solid #ddd;
    font-size: 0.9em;
    padding: 0 10px;
    padding-bottom: 15px;
}

.menu .menuler .menuIcerik {
    padding: 5px 0;
}

.menu .menuler .menuIcerik span {
    display: block;
}

.menu .menuler .menuIcerik .altButton {
    height: 9px;
    padding-bottom: 2px;
    transition: transform 0.5s;
    will-change: 'transform';
    transform: rotate(0deg);
}

.menu .menuler .menuIcerik .altButton.acik {
    transform: rotate(-180deg);
}

.menu .menuler .menuIcerik>div {
    display: none;
}

.menu .menuler .menuIcerik>div>div {
    color: #909090;
    padding: 5px 15px;
    font-family: 'Montserrat-Medium';
    border-bottom: 1px solid #ddd;
}

.menu .menuler .menuIcerik>div:last-child {
    border-bottom: 0;
}

.menu .sosyalMedya {
    width: 100%;
    text-align: center;
    padding: 10px 0;
}

.menu .sosyalMedya>img {
    height: 30px;
    padding: 5px 7px;
}

/* Menu Bitiş */

/* Tipler */

.tipler {
    background-color: #f8f8f8;
    padding-top: 80px;
}

.tipler .tip-item {
    background-color: white;
    border-radius: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 15px;
    border: 0;
}

.tipler span {
    font-size: 10px;
}

.tipler a {
    color: #dcdc0f;
    font-size: 12px;
}

.tip5 {
    column-count: 2;
    align-items: center;
    text-align: center;
    padding-top: 80px;
    margin-left: 10px;
    margin-right: 10px;
}

.tip5 .item {
    margin-bottom: 20px;
    display: inline-block;
}

.tip5 img {
    height: 100px;
    border-radius: 20px;
}

.tip5 .texts {
    text-align: center;
    font-size: 12px;
}

/* Tipler Bitiş */

/* Genel İçerik */

.genel-icerik {
    background-color: #f8f8f8;
    padding-top: 80px !important;
    padding-bottom: 60px !important;
}

.genel-icerik span {
    font-size: 12px;
}

.genel-icerik .baslik {
    width: 86%;
    margin-left: 7%;
    margin-bottom: 20px;
    box-shadow: 2px 2px 7px grey;
    border-radius: 15px;
    padding-top: 7px;
    padding-bottom: 2px;
    padding-left: 15px;
    color: #0c601e;
}

.genel-icerik .cards {
    margin-bottom: 20px;
    background-color: white;
    border-radius: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 20px;
    border: 0;
    z-index: 0;
    overflow-x: scroll;
}

.genel-icerik .duyuru .date {
    color: grey;
    font-size: 12px;
}

/* .fotogaleri {
    column-count: 2;
} */

.fotogaleri .fotogaleriitem img {
    width: 90%;
    border-radius: 20px;
    margin: 9px;
}

.genel-icerik .duyuru .aciklamas {
    font-size: 12px;
}

.genel-icerik .duyuru .aciklamas .butonlar {
    display: flex;
    justify-content: flex-start;
}

.genel-icerik .duyuru .aciklamas .butonlar .butonyeri {
    padding-top: 8px;
    margin-left: 10px;
}

.genel-icerik .foto-galeri {
    flex-direction: row;
    flex-wrap: wrap;
    flex-flow: row wrap;
    column-count: 2;
    padding: 10px;
    margin-bottom: 60px;
}

.genel-icerik .foto-galeri .item {
    display: inline-block;
    font-size: 12px;
    margin-bottom: 20px;
}

.genel-icerik .foto-galeri .item img {
    border-radius: 20px;
    width: 100%;
}

.fotogaleri-load {
    height: 170px;
    border-radius: 20px;
    background: linear-gradient(90deg, rgba(252, 252, 252, 1) 0%, rgb(213, 213, 211) 50%, rgba(244, 245, 245, 1) 100%);
    display: inline-block;
    text-align: center;
    width: 100%;
    padding-top: 55px;
    margin-bottom: 20px;
    animation: blink 1s linear infinite;
}

@keyframes blink {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.fotogaleri-load img {
    height: 50px !important;
    margin-left: 40px;
    margin-right: 40px;
}

.image1load {
    height: 270px;
    border-radius: 20px;
    background: linear-gradient(90deg, rgba(252, 252, 252, 1) 0%, rgb(213, 213, 211) 50%, rgba(244, 245, 245, 1) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    padding-top: 55px;
    margin-bottom: 20px;
    animation: blink 1s linear infinite;
}

@keyframes image1 {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.image2load {
    height: 200px;
    border-radius: 20px;
    background: linear-gradient(90deg, rgba(252, 252, 252, 1) 0%, rgb(213, 213, 211) 50%, rgba(244, 245, 245, 1) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
    animation: blink 1s imagess infinite;
}

@keyframes imagess {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.item-video {
    padding-bottom: 20px;
}

.item-video img {
    width: 80%;
    margin-left: 10%;
    border-radius: 20px;
}

.item-video span {
    font-size: 12px;
}

.genel-icerik .duyuru button {
    font-size: 12px;
    align-items: center;
    text-align: center;
    height: 30px;
    border-radius: 20px;
    background-color: #dcdc0f;
    border: 0;
    color: black;
    padding-left: 30px;
    padding-right: 30px;
}

.genel-icerik .duyuru .aciklama {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    line-height: 15px;
    -webkit-box-orient: vertical;
}

/* Genel İçerik Bitiş */